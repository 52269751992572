// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Button, Icon } from '~/public/shared/components';
import { openFilterModal } from '../actions/items';
import CategoryCrumbs from './CategoryCrumbs';
import FilterCrumbs from './FilterCrumbs';
import SearchFollowBtn from './SearchFollowBtn';
import { findLeaf } from '../utils/tree';
import { nextAppliedParams, paramsToUrl } from '../utils/url';
import type {
  Filter,
  Sorts,
  Pages,
} from '../types';

type Props = {
  pages: Pages,
  filters: Array<Filter>,
  sorts: Sorts,
  keyword: ?string,
  isLoading: boolean,
  openFilterModal: () => void,
  onKeywordClear: () => void,
  appliedParams: Object,
  history: Object,
};

class PageHeading extends Component<Props> {
  handleModalOpenClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.props.openFilterModal();
  }

  handleSortChange = (e: SyntheticInputEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    const { appliedParams } = this.props;

    const nextParams = nextAppliedParams(appliedParams, (current) => {
      return {
        ...current,
        sort: value,
        page: 1,
      };
    });
    const url = paramsToUrl(nextParams);

    this.props.history.push(url);
  };

  renderTitle = (selectedCategory, query) => {
    if (query) {
      return (
        <span>Search for &ldquo;{query}&rdquo;</span>
      );
    } else if (selectedCategory) {
      return selectedCategory.name;
    } else {
      return this.props.appliedParams.sale ? 'Sale Items' : 'Browse All Items';
    }
  }

  renderSorts = () => {
    const { sorts, appliedParams } = this.props;
    const { data, preset } = sorts;
    const selectedSort = appliedParams.sort || preset;

    if (data.length === 0) { return null; }

    return (
      <>
        <div className="visually-hidden" aria-live="polite">{selectedSort}</div>
        <div className="sort-select items-page-heading__sort">
          <label className="sort-select__label" htmlFor="items-sort">Sort By</label>
          <select onChange={this.handleSortChange} value={selectedSort} id="items-sort" className="sort-select__select">
            {
              data.map(
                (sort) => <option value={sort.id} key={sort.id}>{sort.name}</option>
              )
            }
          </select>
        </div>
      </>
    );
  }

  render() {
    const {
      filters,
      pages,
      appliedParams,
    } = this.props;

    const keyword = appliedParams.q;
    const categoryFilter = filters.find((filter) => filter.component === 'category');
    const selectedCategory = (appliedParams.category) ? appliedParams.category.fullSlugPath : '';
    const selectedCategoryTree = categoryFilter && selectedCategory && findLeaf(categoryFilter.data, selectedCategory);
    const items = `${pages.totalItems.toLocaleString()} Item${pages.totalItems === 1 ? '' : 's'}`;
    const viewSearchFollowBtn = (appliedParams.q ||
      appliedParams.sale ||
      appliedParams.seller_id ||
      appliedParams.status ||
      appliedParams.zip ||
      appliedParams.delivery_options.length > 0 ||
      appliedParams.category ||
      appliedParams.days_left);

    return (
      <div className="items-page-heading">
        { categoryFilter &&
          <CategoryCrumbs
            filters={filters}
            filter={categoryFilter}
            selected={selectedCategory}
            keyword={keyword}
            appliedParams={appliedParams}
          />
        }

        <div className="items-page-heading__title-wrap">
          {this.props.appliedParams.sale ? (
            <h2 className="items-page-heading__title">
              {this.renderTitle(selectedCategoryTree, keyword)}
            </h2>
          ) : (
            <h1 className="items-page-heading__title">
              {this.renderTitle(selectedCategoryTree, keyword)}
            </h1>
          )}
          <span className="items-page-heading__title-count">{items}</span>
        </div>

        { viewSearchFollowBtn && <SearchFollowBtn keyword={keyword} appliedParams={appliedParams} /> }

        <Button
          onClick={this.handleModalOpenClick}
          buttonStyle="primary"
          buttonSize="sm"
          className="items-page-heading__filter-btn"
        >
          <Icon icon="filter" className="btn__icon" /> Filter
        </Button>

        <div className="items-page-heading__row">
          <FilterCrumbs onKeywordClear={this.props.onKeywordClear} />
          {this.renderSorts()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  isLoading: store.items.isLoading,
  filters: store.items.filters,
  sorts: store.items.sorts,
  pages: store.items.pages,
  appliedParams: store.items.appliedParameters,
});
const mapDispatchToProps = { openFilterModal };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageHeading));
