// @flow

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import moment from 'moment';
import URI from 'urijs';

import PaymentMethodSvgSprite from '~/public/svgs/PaymentMethodSvgSprite';
import formatCurrency from '~/utils/formatCurrency';
import {
  Button,
  Flash,
} from '~/public/shared/components';
import { addressToString, userAddressIdToObject } from '../utils/addressHelpers';
import { bidIncrement } from '../utils/bid';
import formatPickupTime from '../utils/formatPickupTime';
import BidInput from './BidInput';
import { paymentMethodSvgId, paymentMethodText } from '../utils/paymentMethodHelpers';
import * as ModalActions from '../actions/modal';
import * as BidActions from '../actions/bid';
import { FeatureFlagConsumer } from '../../dashboard/contexts/FeatureFlagContext';

import type {
  Bid,
  Item,
  FulfillmentType,
  User,
  Address,
} from '../types';

type Props = {
  bid: Bid,
  item: Item,
  openModal: (string) => void,
  onBidChange: (Object) => void,
  changePane: (string) => void,
  newBidErrors: ?{
    heading: string,
    message: string[],
    type: string,
  },
  bidInFlight: boolean,
  onFormSubmit: () => void,
  user: User,
  didUserFocusBidAmount: boolean,
  setDidUserFocusBidAmount: (boolean) => void,
  isMaxBid: boolean,
  setIsMaxBid: (boolean) => void,
};

type State = {
  extendedTimeout: ?TimeoutID,
  willExtend: boolean,
}

class PanePlaceBid extends Component<Props, State> {
  state = {
    extendedTimeout: null,
    willExtend: false,
  }

  componentDidMount() {
    this.setExtendedTimeout();
  }

  componentWillUnmount() {
    const { extendedTimeout } = this.state;
    if (extendedTimeout) {
      clearTimeout(extendedTimeout);
    }
  }

  HGTVSales = ['21SCS500', '21SCS501', '21SCS502', '21SCS503', '21SCS504', '21SCS505', '21SCS506', '21SCS507', '21SCS508'];

  setExtendedTimeout = () => {
    const now = moment(window.EBTH.timesync.now());
    const extendablePeriod = moment(this.props.item.saleEndsAt).subtract(5, 'minutes');
    const timeUntilExtendablePeriod = Math.max(extendablePeriod.diff(now, 'milliseconds'), 0);
    return setTimeout(() => {
      this.setState({ willExtend: true });
    }, timeUntilExtendablePeriod);
  }

  handleFormSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.setDidUserFocusBidAmount(false);
    this.props.setIsMaxBid(false);
    this.props.onFormSubmit();
  }

  handleOpenBidIncrementsModal = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.openModal('bidIncrements');
  }

  handleOpenLearnMoreModal = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.openModal('learnMore');
  }

  getFee = (amount: number) => {
    if (amount < 5000) {
      const fee = amount + (amount * 0.035);
      return fee;
    }
    return amount;
  }

  renderSelectedFulfillmentDetails = (fulfillmentType: FulfillmentType, userAddress: ?Address) => {
    const {
      label,
      type,
      startsAtLocalized,
      endsAtLocalized,
      location,
      price,
      pickupInfo,
    } = fulfillmentType;
    const { bid } = this.props;

    if (type === 'shipping') {
      return (
        <Fragment>
          <strong>{label}</strong>{bid.shippingQuote && ` - ${bid.shippingQuote.quote}`}
          {userAddress &&
            <div className="bid__bid-selection-details">
              {addressToString(userAddress)}
            </div>
          }
          {(bid.shippingQuote && !userAddress) &&
            <div className="bid__bid-selection-details">Deliver to {bid.shippingQuote.zip}</div>
          }
        </Fragment>
      );
    } else if (['pickup', 'warehouse'].includes(type)) {
      const hasProcessingLocation = pickupInfo != null;
      let pickupDetails;
      if (hasProcessingLocation) {
        pickupDetails = location && location.displayName;
      } else {
        const cityState = location ? `${location.city}, ${location.state}` : '';
        pickupDetails = `${cityState} - ${formatPickupTime({ startsAtLocalized, endsAtLocalized })}`;
      }

      return (
        <Fragment>
          <strong>{label} - {price === 0 ? 'FREE' : formatCurrency(price)}</strong>
          <div className="bid__bid-selection-details">{pickupDetails}</div>
        </Fragment>
      );
    }
  }

  renderHGTV = (item: Item) => {
    return (
      <div>
        <input
          id="hgtv-confirm"
          type="checkbox"
          className={['bid-input-form__confirm-input', 'qa-confirm-checkbox']}
          onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
            // Fire the Segment Event
            if (e.target.checked) {
              window.analytics.track('HGTV Terms Agreement', { item: item.id, saleCode: item.saleCode });
            }
          }}
        />
        <label
          htmlFor="hgtv-confirm"
          className="bid-input-form__confirm-label qa-confirm-checkbox-label"
        >
          This item may be featured in the TV Show &quot;Everything but the House&quot; Season 2, and you agree to the <a target="_blank" rel="noopener noreferrer" href="https://storage.googleapis.com/ebth-video/Appearance%20Release_EBTHonline.pdf">EBTH Appearance Release</a>.
        </label>
      </div>
    );
  }

  renderBidConfirmationText = (bid: Bid, fulfillmentType: ?FulfillmentType, item: Item, userAddress: ?Address, internationalShippingQuotes: boolean) => {
    const isDebit = bid.paymentMethod && bid.paymentMethod.paymentMethodTypeId === 3;
    let amount = 0;
    if (bid.amount) {
      amount = isDebit ? bid.amount : this.getFee(bid.amount);
    }

    let max = 0;
    let maxHasFee = false;
    if (bid.max) {
      maxHasFee = bid.max < 5000;
      max = isDebit ? bid.max : this.getFee(bid.max);
    }

    const type = fulfillmentType ? fulfillmentType.type : null;

    const isPickup = ['pickup', 'warehouse'].includes(type);
    const authText = (bid.paymentMethod && isDebit)
      ? 'I authorize EBTH to debit my account'
      : 'I agree to pay';
    const amountText = amount > 0 ? <Fragment> <strong>{formatCurrency(amount)}{!isDebit && amount < 5000 && '*'}</strong></Fragment> : '';
    const maxText = (max > 0) && (max > amount) ? <Fragment> or up to <strong>{formatCurrency(max)}{!isDebit && maxHasFee && '*'}</strong></Fragment> : '';
    const isInternational = internationalShippingQuotes && userAddress && userAddress.international;
    const taxLabel = isInternational ? '' : 'tax';
    const shippingAndTax = amount > 0
      ? `, plus ${
        isPickup ? '' : `shipping${isInternational ? ' and' : ','} Shipment Protection${isInternational ? '' : ' and '}`
      }${taxLabel}${item.typeName !== 'buy_now' ? ',' : '.'}`
      : '';

    return (
      <Fragment>
        {authText}{amountText}{maxText}{shippingAndTax}
        {item.typeName !== 'buy_now' && ' if I win.'}
        { !isDebit && amount < 5000 && (
          // EE_DISPLAY_FULL
          <span>* Includes Everything Expedited Service (3.5%)
            {!isPickup && (
              <Fragment>
                Click <a href="https://www.ebth.com/outbound-shipping-insurance" target="_blank" rel="noopener noreferrer">here</a> to learn more about how Shipment Protection costs are calculated.
                { isInternational && ' Any taxes, tariffs or fees of any kind charged by the destination government are the responsibility of the winning bidder.' }
              </Fragment>
            )}
            <div className="bid-input-form__disclosure">
              { item.typeName === 'buy_now' ? 'By selecting Buy Now you acknowledge that this item is not returnable and sold "as is".' : 'By bidding you acknowledge that this item is not returnable and sold "as is".' }
              {(fulfillmentType && ['pickup', 'warehouse'].includes(fulfillmentType.type)) &&
                <Fragment> Failure to pickup within the designated timeframe will result in assessment of storage fees, shipment at the buyers expense and/or forfeiture without reimbursement. </Fragment>
              } <a href="https://www.ebth.com/terms" target="_blank" rel="noopener noreferrer">Terms&nbsp;&amp;&nbsp;Conditions</a>
            </div>
          </span>
        )}
      </Fragment>
    );
  }

  renderDoNotUseMyGiftCardBalance = (bid: Bid, onBidChange: (Object) => void) => {
    return (
      <div>
        <input
          id="use-gift-card-balance"
          type="checkbox"
          className="bid-input-form__confirm-input"
          checked={bid.bypassGiftCardBalance}
          onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
            onBidChange({ ...bid, bypassGiftCardBalance: e.target.checked });
          }}
        />
        <label
          htmlFor="use-gift-card-balance"
          className="bid-input-form__confirm-label qa-confirm-checkbox-label"
        >
          Do not use my gift card balance for this bid
        </label>
      </div>
    );
  }

  render() {
    const {
      bid,
      item,
      onBidChange,
      changePane,
      newBidErrors,
      bidInFlight,
      user,
      didUserFocusBidAmount,
      setDidUserFocusBidAmount,
      isMaxBid,
      setIsMaxBid,
    } = this.props;

    const { fulfillmentType } = bid;
    const isEditingBid = !!bid.id;
    const increment = bidIncrement(item.highBidAmount);
    const nextBidAmount = Math.max(parseInt(item.minimumBidAmount, 10), (item.highBidAmount + increment));
    const nextBidDisplayAmount = formatCurrency(nextBidAmount);
    const amountErrors = [...bid.amountErrors];
    const buildThumbnailUrl = (url: ?string): ?string => {
      if (url != null) {
        return URI(url)
          .query('')
          .query({
            fit: 'crop',
            auto: 'format',
            w: 150,
            h: 150,
          })
          .toString();
      } else {
        return null;
      }
    };

    if (newBidErrors && newBidErrors.type === 'outbid') {
      amountErrors.push(`Minimum bid is now ${nextBidDisplayAmount}.`);
      if (!isMaxBid) {
        onBidChange({ ...bid, amount: nextBidAmount });
        setIsMaxBid(true);
      }
    }

    const userAddress = (bid.address && bid.address.id)
      ? userAddressIdToObject(bid.address.id, user)
      : null;


    const maxInputClasses = classNames(
      'bid-input-form__group',
      {
        'bid-input-form__group--error': bid.maxErrors.length > 0,
      }
    );

    const confirmInputClasses = classNames(
      'bid-input-form__confirm-input',
      'qa-confirm-checkbox',
      {
        'bid-input-form__confirm-input--error': bid.confirmationError,
      }
    );

    const setBidButtonText = () => {
      if (item.typeName === 'buy_now') {
        return 'Buy Now';
      } else if (isEditingBid) {
        return 'Update Bid';
      } else {
        return 'Place Bid';
      }
    };

    return (
      <form onSubmit={this.handleFormSubmit} noValidate>
        {(item.aasmState === 'for_sale' && this.state.willExtend) &&
          <Flash
            flashStyle="warn"
            showIcon
            className="bid__extend-warning"
          >
            Bids placed within the last 5 minutes will automatically extend this
            item’s sale by&nbsp;5&nbsp;minutes.
          </Flash>
        }
        <div className="bid-input-form">
          {item.typeName !== 'buy_now' ? (
            <>
              <div className={maxInputClasses}>
                <label htmlFor="bid-max" className="bid-input-form__label bid-input-form__label--headline">
                  Your Max Bid
                </label>
                <BidInput
                  name="bid-max"
                  id="bid-max"
                  value={bid.max}
                  className="qa-max-bid-input"
                  placeholder={`${isEditingBid ? formatCurrency(parseInt(bid.amount, 10)) : formatCurrency(parseInt(nextBidAmount, 10))} or more`}
                  autoFocus
                  updateBid={(value) => {
                    if (isEditingBid) {
                      onBidChange({ ...bid, max: value });
                    } else {
                      onBidChange({
                        ...bid,
                        amount: Math.min(Number(nextBidAmount), Number(value)),
                        max: value,
                      });
                    }
                  }}
                  allowClearBidOnFocus
                  didUserFocusBidAmount={didUserFocusBidAmount}
                  setDidUserFocusBidAmount={setDidUserFocusBidAmount}
                />
                {amountErrors.length > 0 && (
                  <div className="bid-input-form__error">{amountErrors.join('')}</div>
                )}
                {bid.maxErrors.length > 0 && (
                  <div className="bid-input-form__error">{bid.maxErrors.join('')}</div>
                )}
                <div className="bid-input-form__hint">
                  {!isEditingBid &&
                    !(item.minimumBidAmount > 1 && item.bidsCount === 0) &&
                    `Current Bid + ${formatCurrency(increment)}`}
                  {!isEditingBid &&
                    item.minimumBidAmount > 1 &&
                    item.bidsCount === 0 &&
                    `Opening Bid ${formatCurrency(parseInt(item.minimumBidAmount, 10))} `}
                  <button
                    className="btn btn--link"
                    type="button"
                    onClick={this.handleOpenBidIncrementsModal}
                  >bid increments
                  </button>
                </div>
              </div>
              <div className={maxInputClasses}>
                <div className="bid-input-form__hint">We will make sure you bid in the smallest increments possible, up to your max bid.</div>
              </div>
            </>
          ) : (
            <>
              <img className="buy-now-thumbnail" src={buildThumbnailUrl(item.mainImage)} alt="" />
              <div className="buy-now-text">
                <div className="buy-now-item-name">
                  {item.name}
                </div>
                <br />
                <div className="buy-now-currency">
                  {formatCurrency(item.minimumBidAmount)}
                </div>
              </div>
            </>
          )}
        </div>

        {(!item.automobileCategory &&
          ((bid.amount && bid.amount > 8499) || (bid.max && bid.max > 8499))) &&
          <div className="bid__note__require-certified-funds">Winning bids or purchases over $9,999 invoice value (including any applicable taxes, fees and/or shipping) will be charged a non-refundable deposit of 10% (not to exceed a total deposit amount of $10,000) at the time of item win. The remaining balance will be due via certified funds within 7 days of item win.
          </div>
        }
        {fulfillmentType &&
          <div className="bid__bid-selection bid__bid-selection--first">
            <div className="bid__bid-selection-section bid__bid-selection-section--body">
              {this.renderSelectedFulfillmentDetails(fulfillmentType, userAddress)}
            </div>
            <Button
              onClick={() => changePane('fulfillment')}
              buttonStyle="secondary"
              buttonSize="extra-sm"
              className="bid__bid-selection-section qa-edit-fulfillment"
            >
              Edit
            </Button>
            {(fulfillmentType.type === 'shipping'
              && userAddress && userAddress.country !== 'US') &&
              <div className="bid__bid-selection-note">
                <a href="https://support.ebth.com/s/article/Does-EBTH-ship-outside-the-Continental-United-States" target="_blank" rel="noopener noreferrer">About international shipping</a>
              </div>
            }
          </div>
        }
        <div className="bid__bid-selection">
          <div className="bid__bid-selection-section--service-header">
            <strong>Everything&nbsp;
              { bid.paymentMethod && bid.paymentMethod.paymentMethodTypeId === 1 ? 'Expedited' : 'Standard' }
            </strong>
          </div>
          <PaymentMethodSvgSprite
            icon={paymentMethodSvgId(bid.paymentMethod)}
            className="bid__bid-selection-section--payment-icon"
          />
          <div className="bid__bid-selection-section--body">
            {paymentMethodText(bid.paymentMethod)}
          </div>
          <Button
            onClick={() => changePane('payment')}
            buttonStyle="secondary"
            buttonSize="extra-sm"
            className="bid__bid-selection-section qa-edit-payment"
          >
            Edit
          </Button>
          <div className="bid__bid-selection-note">
            { bid.paymentMethod && bid.paymentMethod.paymentMethodTypeId === 1 ?
              <span>Expedited payment methods are verified and processed immediately.
                {/* EE_DISPLAY_AMOUNT */}
                &nbsp;A&nbsp;3.5%&nbsp;fee&nbsp;applies. </span>
              :
              <span>Standard payment methods can take 5-10 business days to clear.
                No&nbsp;fee&nbsp;applies. </span>
            }
            <button
              className="btn btn--link"
              type="button"
              onClick={this.handleOpenLearnMoreModal}
            >Learn&nbsp;More</button>
            <br />
            {
              item.typeName === 'buy_now'
                ? 'Payment failure may result in item returning to sale.'
                : ''
            }
          </div>
        </div>
        {bid.hasPaymentDiscount &&
          <div className="bid__note">&#42; I have an EBTH gift card or coupon code.</div>
        }
        {item.automobileCategory &&
          <div className="bid__note">Vehicles require wire transfer or cashier’s check for final&nbsp;payment.</div>
        }
        { user.giftCardBalance > 0 && this.renderDoNotUseMyGiftCardBalance(bid, onBidChange) }
        <input
          id="bid-confirm"
          type="checkbox"
          className={confirmInputClasses}
          checked={bid.isConfirmed}
          onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
            onBidChange({ ...bid, isConfirmed: e.target.checked });
          }}
        />
        <label
          htmlFor="bid-confirm"
          className="bid-input-form__confirm-label qa-confirm-checkbox-label"
        >
          <FeatureFlagConsumer>
            {(featureFlags) => (
              this.renderBidConfirmationText(bid, fulfillmentType, item, userAddress, featureFlags.internationalShippingQuotes)
            )}
          </FeatureFlagConsumer>
        </label>
        { this.HGTVSales.includes(item.saleCode) && item.saleCode }
        <div aria-live="polite">
          {newBidErrors &&
            <Flash
              flashStyle="error"
              heading={newBidErrors.heading}
              showIcon
              className="u-mt2"
            >
              {newBidErrors.message.map((message, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index}>{message}</div>
                );
              })}
            </Flash>
          }
        </div>
        <Button
          type="submit"
          name="button"
          buttonDisplay="block"
          buttonStyle="primary-important"
          buttonSize="lg"
          className="u-mt2 qa-confirm-bid-btn"
          inFlight={bidInFlight}
          itemType={item.typeName}
          disabled={bid.max === null && item.typeName !== 'buy_now'}
        >
          { setBidButtonText() }
        </Button>
      </form>
    );
  }
}

const mapStateToProps = ({ item: { item }, user: { user } }) => ({ item, user });
const mapDispatchToProps = (dispatch) => (
  bindActionCreators({ ...BidActions, ...ModalActions }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(PanePlaceBid);
